// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cKLjImYfk", "EqOO6T2VB"];

const serializationHash = "framer-jhPEC"

const variantClassNames = {cKLjImYfk: "framer-v-ugdtmq", EqOO6T2VB: "framer-v-1qfe1zw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "cKLjImYfk", "Variant 2": "EqOO6T2VB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cKLjImYfk"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cKLjImYfk", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearfzmf19 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 1000)
})

useOnVariantChange(baseVariant, {default: onAppearfzmf19})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ugdtmq", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"cKLjImYfk"} ref={ref ?? ref1} style={{backgroundColor: "rgb(44, 8, 128)", borderBottomRightRadius: 250, borderTopLeftRadius: 250, boxShadow: "0px 0px 3px 3px rgb(255, 175, 46)", ...style}} variants={{EqOO6T2VB: {backgroundColor: "rgb(255, 209, 110)", boxShadow: "0px 0px 3px 3px rgb(6, 46, 112)"}}} {...addPropertyOverrides({EqOO6T2VB: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QWxhdHNpLXJlZ3VsYXI=", "--framer-font-family": "\"Alatsi\", \"Alatsi Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>All Types Of Loan</motion.p></React.Fragment>} className={"framer-xlawb4"} fonts={["GF;Alatsi-regular"]} layoutDependency={layoutDependency} layoutId={"AKzI23rZV"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} variants={{EqOO6T2VB: {"--extracted-r6o4lv": "rgb(44, 8, 128)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({EqOO6T2VB: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7QWxhdHNpLXJlZ3VsYXI=", "--framer-font-family": "\"Alatsi\", \"Alatsi Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(44, 8, 128))"}}>All Types Of Loan</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jhPEC.framer-1fk40xc, .framer-jhPEC .framer-1fk40xc { display: block; }", ".framer-jhPEC.framer-ugdtmq { height: 36px; position: relative; width: 244px; }", ".framer-jhPEC .framer-xlawb4 { flex: none; height: auto; left: 52%; position: absolute; top: 47%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 244
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"EqOO6T2VB":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqsWNHs1_c: React.ComponentType<Props> = withCSS(Component, css, "framer-jhPEC") as typeof Component;
export default FramerqsWNHs1_c;

FramerqsWNHs1_c.displayName = "Types Of Loan";

FramerqsWNHs1_c.defaultProps = {height: 36, width: 244};

addPropertyControls(FramerqsWNHs1_c, {variant: {options: ["cKLjImYfk", "EqOO6T2VB"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerqsWNHs1_c, [{explicitInter: true, fonts: [{family: "Alatsi", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/alatsi/v13/TK3iWkUJAxQ2nLNGGzUHte5fKg.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})